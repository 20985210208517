import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container, Actions } from "../layoutComponents"
import { AnchorPrimary } from "../buttons"
import { FaPhoneAlt } from "react-icons/fa"

const device = {
  md: "48em",
}

const ActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  color: var(--txt-light);
  background: ${props => `url(${props.img})`};
  /* background-position: center; */
  background-size: cover;
  background-repeat: no-repeat;
  padding: 6em 0;
  @media screen and (max-width: ${device.md}) {
  }
`

const ActionText = styled.div`
  padding: 3em 4em;
  background: var(--clr-overlay-darker);
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 70ch;
  h1,
  h2,
  h3,
  h4,
  p {
    line-height: 1.1;
  }
`

const TextSecondary = styled.p`
  color: var(--txt-light-secondary);
`

const Flex = styled.div`
  display: flex;
  flex-direction: column;
  p {
    font-size: 10px;
  }
`

export default function CallToAction(props) {
  return (
    <ActionWrapper img={props.img}>
      <Container className="spacing">
        <ActionText className="spacing">
          <p className="title bold italics">{props.textPrimary}</p>
          <TextSecondary className="bold">
            Request a service, schedule an appointment, or get a quote on your
            residential, commercial, or industrial electrical projects.
            {/* For a
            quick response, send a text: */}
          </TextSecondary>
          <Actions>
            <AnchorPrimary href="tel: 905-580-4559">
              <Flex>
                <p>call or text</p>
                <div>
                  <FaPhoneAlt />
                  <span>905-580-4559</span>
                </div>
              </Flex>
            </AnchorPrimary>
          </Actions>
        </ActionText>
      </Container>
    </ActionWrapper>
  )
}
