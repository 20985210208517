import React from "react"
import styled from "styled-components"

import Logo from "../../images/royco-logo.svg"
import { Section, Container, Flex } from "../layoutComponents"

const Wrapper = styled.div``

const FlexItem = styled.div`
  display: flex;
  align-items: center;

  img {
    width: 50px;
  }

  p {
    text-transform: uppercase;
  }
`

export default function Benefits() {
  return (
    <Wrapper>
      <Flex>
        <FlexItem>
          <img src={Logo} alt="royco electric" />
          <p>licensed</p>
        </FlexItem>
        <FlexItem>
          <img src={Logo} alt="royco electric" />
          <p>insured</p>
        </FlexItem>
        <FlexItem>
          <img src={Logo} alt="royco electric" />
          <p>listed</p>
        </FlexItem>
      </Flex>
    </Wrapper>
  )
}
