import React from "react"
import styled from "styled-components"
import { Section, Container, Flex } from "../layoutComponents"
import Benefits from "./benefits"

import AboutUs from "../../images/about-us.png"
import Title from "../title"

const List = styled.ul`
  margin: 0;
  padding: 0;
`

const ImgWrapper = styled.div``

export default function Company() {
  return (
    <Section>
      <Container>
        <Title title="About us" />
        <Flex>
          <List className="spacing">
            <li>
              We are a licensed and insured electrical contractor servicing the
              GTA and surrounding areas including Muskoka.
            </li>
            <li>
              We provide full residential services - re-wires (knob and tube),
              custom homes, cottages, boathouses, service upgrades including
              maintenance and generators.
            </li>
            <li>
              We provide retrofitted shipping container wiring solutions for
              container homes, cottages, bunkies including retail and pop up
              shops ESAFE/CSA certified.
            </li>
            <li>
              We provide commercial services - new construction, offices, store
              fronts and retail spaces.
            </li>
            <li>
              We service manufacturing and industrial plants including power
              feeds, new machinery installs, MCC, plant maintenance, shut-downs
              and lighting maintenance.
            </li>
            <li>
              20+ years of experience in manufacturing and project management.
            </li>
          </List>
          <div>
            <ImgWrapper>
              <img src={AboutUs} alt="royco electric" />
            </ImgWrapper>
          </div>
        </Flex>
      </Container>
    </Section>
  )
}
