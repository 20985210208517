import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import BannerActions from "../components/banners/bannerActions"
import Company from "../components/about/company"
import Bio from "../components/about/bio"
import FeaturedTestimonial from "../components/testimonials/featuredTestimonial"
import CallToAction from "../components/banners/callToAction"

import Banner from "../images/banner.png"
import ActionBanner from "../images/action-banner.png"

export default function About() {
  return (
    <Layout>
      <SEO title="About Us" />
      <BannerActions img={Banner} />
      <Company />
      <Bio />
      <CallToAction
        img={ActionBanner}
        textPrimary="call royco electric today"
      />
      <FeaturedTestimonial
        testimonial="Extremely professional team and clean, beautiful work that is always safe and to code. Never seen anyone make a panel look like a piece of art!"
        company="Homeowner"
        name="SARA GOODFELLOW"
      />
    </Layout>
  )
}
