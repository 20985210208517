import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"

import { Section, Container, Flex } from "../layoutComponents"
import Title from "../title"

import JamesRoss from "../../images/james-ross.png"

const AboutText = styled.div`
  /* align-self: center; */
`
const AboutImg = styled.div``

const Name = styled.div``

export default function Bio() {
  return (
    <Section>
      <Container>
        <Title title="meet our master electrician" />
        <Flex>
          <AboutText className="spacing-md">
            <div className="spacing">
              <p className="body body--large">the history of royco electric:</p>
              <p className="body">
                In honour of my late father Jim Ross, I decided to use Royco as
                our operating name. Royco Construction was a general contracting
                company founded by my grandfather back in the early 80’s and was
                managed and eventually owned by my father until the late 90's.
                In 2018, I decided to revive the name Royco to keep the legacy
                of quality service and reliability going in tribute to my
                family.
              </p>
            </div>
            <div className="spacing">
              <p className="body body--large">meet our master electrician:</p>
              <p className="body">
                With 8 years experience in manufacturing, fabrication, machinery
                maintenance and troubleshooting, James then refined his focus to
                electrical trades for the past 15 years. With a combined 22
                years of experience and obtaining his Master Electrician
                certification, James and the Royco team have the skills, drive
                and passion to accommodate a wide range of solutions to any
                project.
              </p>
            </div>
          </AboutText>
          <AboutImg>
            <img src={JamesRoss} alt="james ross headshot" />
            <Name>
              <p className="body--large">james w. ross</p>
              <p className="body italics">Owner of Royco Electric Ltd.</p>
              <p className="body italics">Master Electrician</p>
            </Name>
          </AboutImg>
        </Flex>
      </Container>
    </Section>
  )
}
